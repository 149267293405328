import { useEffect, useState } from 'react';

export default function useIsAnyFocused(): boolean {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    const listener = (e: FocusEvent): void => {
      if (e.type === 'focusin') {
        setFocused(true);
      } else if (e.type === 'focusout') {
        // since the user may tab between fields, we must delay and ensure they didn't focus in again
        setTimeout(() => {
          if (document.activeElement === document.body) {
            setFocused(false);
          }
        }, 0);
      }
    };

    setFocused(document.activeElement !== document.body);

    window.addEventListener('focusin', listener);
    window.addEventListener('focusout', listener);

    return () => {
      window.removeEventListener('focusin', listener);
      window.removeEventListener('focusout', listener);
    };
  }, []);

  return focused;
}
